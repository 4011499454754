<template lang="pug">
settings-page(title="Системные настройки")
  settings-panel(v-loading="isFetching")
    template(#header) Архив
    .system-settings
      span Укажите, через какое количество дней отмечать закупки просмотренными:
      validation-row.archive-input(
        custom
        not-empty
        watch-validation
        type="number"
        v-model="data.templateMarker.daysBeforeMark"
        info-message="От 1 до 7 дней"
        :custom-checker="customValidatorDays"
        :disabled="!isActive"
        @error="errorChecker('daysBeforeMark')"
        @correct="correctChecker('daysBeforeMark')"
      )
    .system-settings
      span Укажите, через какое количество дней помещать неактуальные закупки в архив:
      validation-row.archive-input(
        custom
        not-empty
        watch-validation
        type="number"
        v-model="data.archive.archive"
        info-message="От 1 до 99 дней"
        :custom-checker="customValidatorArchive"
        :disabled="!isActive"
        @error="errorChecker('archive')"
        @correct="correctChecker('archive')"
      )
    ui-checkbox(
      id="showArchived"
      label="Показывать архивные закупки"
      v-model="data.archive.showArchived"
      :disabled="!isActive"
    )
    span.info-message
      | Неактуальными закупками считаются заявки в статусах "отказ от закупки", "отклонено" и "поражение".
      |
      | В течение указанного кол-ва дней, неактуальные закупки архивируются, и отображаются в списке заявок исходя из настройки "показывать архивные закупки".
    span.info-message
      | Закупки, помещенные в статус "архив", архивируются автоматически в момент перевода статуса.
    template(v-if="showErrorWhileSave || showErrorWhileFetch" v-slot:comments)
      span.error-message(v-if="showErrorWhileFetch") Не удалось получить данные, попробуйте позже
      span.error-message(v-if="showErrorWhileSave") Возникла ошибка при сохранении данных, попробуйте позже
    template(#actions v-if="isActive")
      ui-button(:loader="isSaving" @click="validateForm") Сохранить
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useForm } from "@/use/other/useForm";
import { useApi } from '@/use/api/useApi';
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";

import SettingsPanel from "@/components/pages/settings/common/SettingsPanel.vue";
import ValidationRow from "@/components/ui/form/input/ValidationRow.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import UiButton from "@/components/ui/button/UiButton.vue";

import type { SystemSettingsI } from "@/stores/settings/SettingsInterfaces";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import SettingsPage from "~/components/pages/settings/common/SettingsPage.vue";

const defaultSettings : SystemSettingsI = {
  archive: {
    archive: 30,
    showArchived: false,
  },
  templateMarker: {
    daysBeforeMark: 3,
  },
};

export default defineComponent({
  name: "ArchiveManagement",
  components: {
    SettingsPage,
    UiButton,
    UiCheckbox,
    ValidationRow,
    SettingsPanel,
  },
  setup() {

    const showErrorWhileFetch = ref(false);
    const showErrorWhileSave = ref(false);

    const { roleAdmin, isTmManager } = useUserAccess();
    const { getQuery, assignCompanyId } = useSuperAccount();

    const data = ref(defaultSettings)
    const isSaving = ref(false)
    const isFetching = ref(true)

    const isActive = computed(() => roleAdmin.value || isTmManager.value)

    /** запрос данных */
    useApi().settings.company.fetchCompanySettings<SystemSettingsI>(getQuery())
      .then((rawData) => {
        data.value = rawData
        showErrorWhileFetch.value = false
      })
      .catch(() => showErrorWhileFetch.value = true)
      .finally(() => isFetching.value = false)

    /** сохранение данных */
    function saveSettings() {
      isSaving.value = true

      useApi().settings.company.patchCompanySettings(assignCompanyId(data.value))
        .then(() => {
          showErrorWhileSave.value = false;
          useNotificationsStore().showSuccess();
        })
        .catch(() => showErrorWhileSave.value = true)
        .finally(() => isSaving.value = false);
    }

    function customValidatorArchive(value: string) {
      return Number(value) >= 1 && Number(value) <= 99 ? '' : 'Введите значение от 1 до 99';
    }

    function customValidatorDays(value: string) {
      return Number(value) >= 1 && Number(value) <= 7 ? '' : 'Введите значение от 1 до 7';
    }

    const { validateForm, errorChecker, correctChecker } = useForm(saveSettings);

    return {
      data,
      isFetching,
      isSaving,
      showErrorWhileFetch,
      showErrorWhileSave,
      isActive,
      validateForm,
      errorChecker,
      correctChecker,
      customValidatorDays,
      customValidatorArchive,
    };
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';
$input-width: 120px;

:deep(.settings-panel) {
  max-width: 600px;
}

.system-settings {
  display: grid;
  grid-template-columns: 3fr $input-width;
  gap: 12px;

  font-size: 14px;
  line-height: 18px;
}

.error-message {
  color: var(--main-red-color);
}

.info-message {
  @include label-13-16;
  color: var(--main-placeholder-color);
}

@media (max-width: 520px) {
  .system-settings {
    display: flex;
    flex-flow: column;

    :deep(.form-input-container) {
      width: $input-width;
    }
  }
}
</style>
